var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center border-bottom pb-3 mb-4"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v(_vm._s(_vm.type === 'create' ? 'Create File' : 'Edit File'))])]), _vm.type === 'create' ? _c('button', {
    staticClass: "btn btn-link text-danger fa-lg",
    attrs: {
      "data-cy": "contract-close"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$emit('close');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  })]) : _vm._e()]), _c('div', {
    staticClass: "row push"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('div', [_c('FormGroup', {
    attrs: {
      "id": "name",
      "type": "text",
      "label": "Name",
      "description": "Enter a label for this document such as a credit note number",
      "error": _vm.validationErrors.name
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "type",
      "type": "select",
      "label": "Type",
      "options": _vm.typeOptions,
      "error": _vm.validationErrors.type
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("File")]), _c('FileUpload', {
    attrs: {
      "existing-file-name": _vm.form.s3Key ? _vm.form.fileName : null,
      "loading": _vm.loadingAction.uploadCreditNote
    },
    on: {
      "reset": _vm.onClearFile
    },
    model: {
      value: _vm.form.file,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "file", $$v);
      },
      expression: "form.file"
    }
  })], 1), _c('FormGroup', {
    attrs: {
      "id": "date",
      "type": "datePicker",
      "label": "Invoice Date",
      "error": _vm.validationErrors.date
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "stampDate",
      "type": "datePicker",
      "label": "Stamp Date",
      "error": _vm.validationErrors.stampDate
    },
    model: {
      value: _vm.form.stampDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "stampDate", $$v);
      },
      expression: "form.stampDate"
    }
  })], 1)])]), _vm._m(1), _c('div', {
    staticClass: "row push"
  }, [_vm._m(2), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "startDate",
      "type": "datePicker",
      "label": "Start Date",
      "error": _vm.validationErrors.startDate
    },
    model: {
      value: _vm.form.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "startDate", $$v);
      },
      expression: "form.startDate"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "endDate",
      "type": "datePicker",
      "label": "End Date",
      "error": _vm.validationErrors.endDate
    },
    model: {
      value: _vm.form.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "endDate", $$v);
      },
      expression: "form.endDate"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "netAmount",
      "type": "number",
      "step": "0.01",
      "label": "Net Amount"
    },
    model: {
      value: _vm.form.netAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "netAmount", $$v);
      },
      expression: "form.netAmount"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "taxAmount",
      "type": "number",
      "step": "0.01",
      "label": "Tax Amount"
    },
    model: {
      value: _vm.form.taxAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "taxAmount", $$v);
      },
      expression: "form.taxAmount"
    }
  }), _vm.selectedAccount.type !== 'water' ? _c('FormGroup', {
    attrs: {
      "id": "totalUnits",
      "type": "number",
      "label": "Total Units",
      "error": _vm.validationErrors.totalUnits
    },
    model: {
      value: _vm.form.totalUnits,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "totalUnits", $$v);
      },
      expression: "form.totalUnits"
    }
  }) : _vm._e(), _vm.selectedAccount.type === 'water' ? _c('FormGroup', {
    attrs: {
      "id": "totalWaterVolume",
      "type": "number",
      "label": "Total Water Volume",
      "error": _vm.validationErrors.totalWaterVolume
    },
    model: {
      value: _vm.form.totalWaterVolume,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "totalWaterVolume", $$v);
      },
      expression: "form.totalWaterVolume"
    }
  }) : _vm._e(), _vm.selectedAccount.type === 'water' ? _c('FormGroup', {
    attrs: {
      "id": "totalWasteVolume",
      "type": "number",
      "label": "Total Waste Volume",
      "error": _vm.validationErrors.totalWasteVolume
    },
    model: {
      value: _vm.form.totalWasteVolume,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "totalWasteVolume", $$v);
      },
      expression: "form.totalWasteVolume"
    }
  }) : _vm._e(), _vm.value.type === 'creditNote' ? _c('FormGroup', {
    attrs: {
      "id": "isPaid",
      "type": "radio",
      "options": [{
        value: false,
        label: 'No'
      }, {
        value: true,
        label: 'Yes'
      }],
      "label": "Is Paid?"
    },
    model: {
      value: _vm.form.isPaid,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isPaid", $$v);
      },
      expression: "form.isPaid"
    }
  }) : _vm._e()], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Enter the details associated with this invoice. These details can be edited after creation.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info-circle text-muted mr-1"
  }), _vm._v(" Misc Fields")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("These are optional fields that you can add to the invoice.")])]);
}]

export { render, staticRenderFns }