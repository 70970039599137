<template>
  <div>
    <div class="d-flex justify-content-between align-items-center border-bottom pb-3 mb-4">
      <h4 class="mb-0">
        <span>{{ type === 'create' ? 'Create File' : 'Edit File' }}</span>
      </h4>
      <button v-if="type === 'create'" data-cy="contract-close" class="btn btn-link text-danger fa-lg" @click.prevent="$emit('close')">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div class="row push">
      <div class="col-lg-4">
        <p class="text-muted">Enter the details associated with this invoice. These details can be edited after creation.</p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <div>
          <FormGroup
            id="name"
            v-model="form.name"
            type="text"
            label="Name"
            description="Enter a label for this document such as a credit note number"
            :error="validationErrors.name"
          />
          <FormGroup id="type" v-model="form.type" type="select" label="Type" :options="typeOptions" :error="validationErrors.type" />
          <div class="form-group">
            <label>File</label>
            <FileUpload
              v-model="form.file"
              :existing-file-name="form.s3Key ? form.fileName : null"
              :loading="loadingAction.uploadCreditNote"
              @reset="onClearFile"
            />
          </div>
          <FormGroup id="date" v-model="form.date" type="datePicker" label="Invoice Date" :error="validationErrors.date" />
          <FormGroup id="stampDate" v-model="form.stampDate" type="datePicker" label="Stamp Date" :error="validationErrors.stampDate" />
        </div>
      </div>
    </div>

    <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info-circle text-muted mr-1"></i> Misc Fields</h2>
    <div class="row push">
      <div class="col-lg-4">
        <p class="text-muted">These are optional fields that you can add to the invoice.</p>
      </div>

      <div class="col-lg-8 col-xl-5">
        <FormGroup id="startDate" v-model="form.startDate" type="datePicker" label="Start Date" :error="validationErrors.startDate" />
        <FormGroup id="endDate" v-model="form.endDate" type="datePicker" label="End Date" :error="validationErrors.endDate" />
        <FormGroup id="netAmount" v-model="form.netAmount" type="number" step="0.01" label="Net Amount" />
        <FormGroup id="taxAmount" v-model="form.taxAmount" type="number" step="0.01" label="Tax Amount" />
        <FormGroup
          v-if="selectedAccount.type !== 'water'"
          id="totalUnits"
          v-model="form.totalUnits"
          type="number"
          label="Total Units"
          :error="validationErrors.totalUnits"
        />
        <FormGroup
          v-if="selectedAccount.type === 'water'"
          id="totalWaterVolume"
          v-model="form.totalWaterVolume"
          type="number"
          label="Total Water Volume"
          :error="validationErrors.totalWaterVolume"
        />
        <FormGroup
          v-if="selectedAccount.type === 'water'"
          id="totalWasteVolume"
          v-model="form.totalWasteVolume"
          type="number"
          label="Total Waste Volume"
          :error="validationErrors.totalWasteVolume"
        />
        <FormGroup
          v-if="value.type === 'creditNote'"
          id="isPaid"
          v-model="form.isPaid"
          type="radio"
          :options="[
            { value: false, label: 'No' },
            { value: true, label: 'Yes' }
          ]"
          label="Is Paid?"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import FileUpload from '@/components/FileUpload';
import Form from '@/components/forms/Form';
import FormGroup from '@/components/FormGroup';

export default {
  name: 'CreditNoteForm',
  components: {
    FileUpload,
    FormGroup
    // ConfirmModal
  },
  extends: Form,
  props: {
    type: {
      required: false,
      type: String,
      default: 'create'
    }
  },
  computed: {
    ...mapGetters({
      validationErrors: 'account/validationErrors',
      company: 'company/company',
      loadingAction: 'account/loadingAction',
      selectedAccount: 'asset/selectedAccount'
    }),
    typeOptions() {
      return [
        { label: 'Credit Note', value: 'creditNote' },
        { label: 'Invoice', value: 'invoice' },
        { label: 'Other', value: 'other' },
        ...this.company.settings.miscInvoiceCategories.map(cat => {
          return { label: cat, value: cat };
        })
      ];
    }
  },
  methods: {
    onClearFile() {
      this.form.file = null;
      this.form.s3Key = null;
      this.form.fileName = null;
    }
  }
};
</script>
